import { useEffect, useState} from 'react';
import axios from 'axios';

const useFetchLive = () => {
  const [loading, setLoading] = useState(true);
  const [live, setLive] = useState(false)

  useEffect(() => {
    let controller = new AbortController();
    //https://halloradi0.airtime.pro/api/live-info
    //https://hallo.ubermorgen.info/api/nowplaying
    axios.get("https://hallo.ubermorgen.info/api/nowplaying", {
      signal: controller.signal 
    }) 
    .then(function (response) {
      console.log(loading + " loading")
      setLive(response.data[0].now_playing.played_at !== 0)
      setLoading(false)  
    })
    .catch(function (err) {
      console.log("error", err)
      setLoading(false)  
    })
    return () => {
      setLoading(false)  
      controller.abort()
    }
  }, [loading]);
  
  return [
    live,
    loading,
  ];
};


export default useFetchLive;
